import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MdxLayout.tsx";
import SEO from 'src/components/SEO';
import MdxCard from 'src/components/MdxCard';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Prisma admin table" description="Auto Generate your React Admin panel for Prisma projects In minutes" mdxType="SEO" />
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "introduction"
      }}>{`Introduction`}</h2>
      <p><a parentName="p" {...{
          "href": "https://npmjs.org/package/@paljs/admin"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/npm/v/@paljs/admin.svg",
            "alt": "Version"
          }}></img></a>{`
`}<a parentName="p" {...{
          "href": "https://npmjs.org/package/@paljs/admin"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/npm/dt/@paljs/admin.svg",
            "alt": "Downloads/total"
          }}></img></a>{`
`}<a parentName="p" {...{
          "href": "https://paljs.com/"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/npm/l/@paljs/admin.svg",
            "alt": "License"
          }}></img></a></p>
      <p>{`We try to build Prisma db CRUD tables with ability to customize this tables with beautiful UI.`}</p>
      <blockquote>
        <p parentName="blockquote">{`NOTE: We have already Full stack projects `}<a parentName="p" {...{
            "href": "/cli/create"
          }}>{`With NextJS and GatsbyJS`}</a></p>
      </blockquote>
      <p><strong parentName="p">{`Install`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "shell"
      }}><pre parentName="div" {...{
          "className": "language-shell"
        }}><code parentName="pre" {...{
            "className": "language-shell"
          }}><span parentName="code" {...{
              "className": "token function"
            }}>{`yarn`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`add`}</span>{` @paljs/admin
or
`}<span parentName="code" {...{
              "className": "token function"
            }}>{`npm`}</span>{` i @paljs/admin`}</code></pre></div>
      <p><strong parentName="p">{`CONTENT`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://prisma-admin.paljs.com"
          }}>{`Online Demo`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#settings"
          }}>{`Settings`}</a>
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "#add-graphql-queries-and-mutation"
              }}>{`Add graphql queries and mutation`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "#add-settings-react-component"
              }}>{`Add Settings React Component`}</a>
              <ul parentName="li">
                <li parentName="ul"><a parentName="li" {...{
                    "href": "#models-card"
                  }}>{`Models card`}</a></li>
                <li parentName="ul"><a parentName="li" {...{
                    "href": "#fields-accordions"
                  }}>{`Fields Accordions`}</a></li>
                <li parentName="ul"><a parentName="li" {...{
                    "href": "#props"
                  }}>{`Props`}</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#prisma-table"
          }}>{`Prisma table`}</a>
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "#using-with-nextjs"
              }}>{`Using with NextJS`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "#using-with-gatsbyjs"
              }}>{`Using with GatsbyJS`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "#props-1"
              }}>{`Props`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "#how-to-add-and-update-list-values"
              }}>{`How to add and update list values?`}</a></li>
          </ul>
        </li>
      </ul>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "settings"
      }}>{`Settings`}</h2>
      <p>{`To be able to custom your tables you need to generate `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`adminSettings.json`}</code>{` file and use it as DB to get table settings from it.`}</p>
      <p><strong parentName="p">{`Generate settings schema`}</strong></p>
      <p>{`1- with our cli `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`pal g`}</code></p>
      <p>{`add in your `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`pal.js`}</code>{` config file this settings`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "text"
      }}><pre parentName="div" {...{
          "className": "language-text"
        }}><code parentName="pre" {...{
            "className": "language-text"
          }}>{`frontend: {
  admin: true,
},`}</code></pre></div>
      <p>{`2- with code use our `}<a parentName="p" {...{
          "href": "/generator#uigenerator"
        }}><code parentName="a" {...{
            "className": "language-text"
          }}>{`UIGenerator`}</code></a>{` class`}</p>
      <h3 {...{
        "id": "add-graphql-queries-and-mutation"
      }}>{`Add graphql queries and mutation`}</h3>
      <p>{`To be able to update `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`adminSettings.json`}</code>{` file with your custom setting in a beautiful UI you have to add 1 query to pull schema in frontend and 2 mutations one for an update model settings and other for update field.`}</p>
      <p>{`If you use `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`Nexus`}</code>{` all you need to be sure you added `}<a parentName="p" {...{
          "href": "/generator/nexus#add-paljs-plugin"
        }}>{`nexus-paljs`}</a>{` plugin in your backend`}</p>
      <p>{`If you use another way you need to add these resolvers and typeDev to your backend graphql schema`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "shell"
      }}><pre parentName="div" {...{
          "className": "language-shell"
        }}><code parentName="pre" {...{
            "className": "language-shell"
          }}><span parentName="code" {...{
              "className": "token function"
            }}>{`yarn`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`add`}</span>{` lowdb`}</code></pre></div>
      <p>{`1- create resolver file and add`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` low `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'lowdb'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` FileSync `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'lowdb/adapters/FileSync'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` adapter `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`new`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`FileSync`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
  `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span>{`key`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`string`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span>{`key`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`string`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span>{`key`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`string`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`any`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'prisma/adminSettings.json'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` db `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`low`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`adapter`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`default`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  Query`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`getSchema`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` db`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`value`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  Mutation`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`updateModel`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` id`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` data `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` db`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token keyword"
            }}>{`get`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'models'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`find`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` id `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`assign`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`data`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`write`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    `}<span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`updateField`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` id`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` modelId`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` data `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` db`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token keyword"
            }}>{`get`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'models'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`find`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` id`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` modelId `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token keyword"
            }}>{`get`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'fields'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`find`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` id `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`assign`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`data`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`write`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <p>{`2- create typeDefs file and add`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` gql `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'graphql-tag'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`default`}</span>{` gql`}<span parentName="code" {...{
              "className": "token template-string"
            }}><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`
  type Schema {
    enums: [Enum!]!
    models: [Model!]!
  }

  type Query {
    getSchema: Schema!
  }

  type Mutation {
    updateField(data: UpdateFieldInput, id: String!, modelId: String!): Field!
    updateModel(data: UpdateModelInput, id: String!): Model!
  }

  type Enum {
    fields: [String!]!
    name: String!
  }

  type Model {
    create: Boolean!
    delete: Boolean!
    displayFields: [String!]!
    fields: [Field!]!
    id: String!
    idField: String!
    name: String!
    update: Boolean!
  }

  type Field {
    create: Boolean!
    editor: Boolean!
    filter: Boolean!
    id: String!
    isId: Boolean!
    kind: KindEnum!
    list: Boolean!
    name: String!
    order: Int!
    read: Boolean!
    relationField: Boolean
    required: Boolean!
    sort: Boolean!
    title: String!
    type: String!
    unique: Boolean!
    update: Boolean!
  }

  input UpdateFieldInput {
    create: Boolean
    editor: Boolean
    filter: Boolean
    id: String
    isId: Boolean
    kind: KindEnum
    list: Boolean
    name: String
    order: Int
    read: Boolean
    relationField: Boolean
    required: Boolean
    sort: Boolean
    title: String
    type: String
    unique: Boolean
    update: Boolean
  }

  input UpdateModelInput {
    create: Boolean
    delete: Boolean
    displayFields: [String!]
    fields: [UpdateFieldInput!]
    idField: String
    name: String
    update: Boolean
  }
  enum KindEnum {
    enum
    object
    scalar
  }
`}</span><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h3 {...{
        "id": "add-settings-react-component"
      }}>{`Add Settings React Component`}</h3>
      <p>{`You have `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`Settings`}</code>{` react component to change your tables settings.`}</p>
      <blockquote>
        <p parentName="blockquote">{`NOTE: You must add this component under `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`ApolloProvider`}</code>{` component because we use `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`@apollo/client`}</code>{` to query and update settings.`}</p>
      </blockquote>
      <p>{`Now you can add our component to any page like this.`}</p>
      <div {...{
        "className": "gatsby-highlight has-highlighted-lines",
        "data-language": "jsx"
      }}><pre parentName="div" {...{
          "className": "language-jsx"
        }}><code parentName="pre" {...{
            "className": "language-jsx"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` React `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'react'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}><span parentName="span" {...{
                "className": "token keyword"
              }}>{`import`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` Settings `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{` `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`from`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'@paljs/admin/Settings'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/admin/style.css'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`default`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`function`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`SettingsPage`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  `}<span parentName="span" {...{
                "className": "token comment"
              }}>{`// Settings component not have any props`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`Settings`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
      <p>{`When you open this in your browser will get it like this image.`}</p>
      <img className="img-border" src="/settings.png" alt="settings" />
      <h4 {...{
        "id": "models-card"
      }}>{`Models card`}</h4>
      <ul>
        <li parentName="ul"><strong parentName="li">{`Models select menu:`}</strong>{` to change between your schema models.`}</li>
        <li parentName="ul"><strong parentName="li">{`Database Name:`}</strong>{` your original Model name like your schema.prisma.`}</li>
        <li parentName="ul"><strong parentName="li">{`Display Name:`}</strong>{` Model name to display in model table page.`}</li>
        <li parentName="ul"><strong parentName="li">{`Id field:`}</strong>{` field that have `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`@id`}</code>{` attribute in your model.`}</li>
        <li parentName="ul"><strong parentName="li">{`Display Fields`}</strong>{` you can select one or more to display in relation tables.`}</li>
        <li parentName="ul"><strong parentName="li">{`Actions`}</strong>{` add actions to your table create, update and delete.`}</li>
      </ul>
      <h4 {...{
        "id": "fields-accordions"
      }}>{`Fields Accordions`}</h4>
      <p><strong parentName="p">{`order`}</strong>{` you can sort this fields in table view, update form and create form by Drag and Drop.`}</p>
      <p>{`Every field has Accordion with this content:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`Database Name:`}</strong>{` your original Field name like your schema.prisma.`}</li>
        <li parentName="ul"><strong parentName="li">{`Display Name:`}</strong>{` it will display into table page, update form and create form.`}</li>
        <li parentName="ul"><strong parentName="li">{`Actions`}</strong>
          <ul parentName="li">
            <li parentName="ul"><strong parentName="li">{`read`}</strong>{` show this field in table view.`}</li>
            <li parentName="ul"><strong parentName="li">{`create`}</strong>{` show this field in create record form.`}</li>
            <li parentName="ul"><strong parentName="li">{`update`}</strong>{` show this field in update record form.`}</li>
            <li parentName="ul"><strong parentName="li">{`filter`}</strong>{` add filter option to this field in table if read checked.`}</li>
            <li parentName="ul"><strong parentName="li">{`sort`}</strong>{` add sortBy option to this field in table if read checked.`}</li>
            <li parentName="ul"><strong parentName="li">{`editor`}</strong>{` use the `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`Editor`}</code>{` component in the update and create. You must send this component into `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`formInputs`}</code>{` prop because we do not have any default one for this option.`}</li>
            <li parentName="ul"><strong parentName="li">{`upload`}</strong>{` use the `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`Upload`}</code>{` component in the update and create. You must send this component into `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`formInputs`}</code>{` prop because we do not have any default one for this option.`}</li>
          </ul>
        </li>
      </ul>
      <h4 {...{
        "id": "props"
      }}>{`Props`}</h4>
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`language`}</code>{` object with the language keys`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` defaultLanguage `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  dir`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'ltr'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  header`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Update models Tables'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  dbName`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Database Name'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  displayName`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Display Name'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  modelName`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Model Name'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  idField`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Id Field'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  displayFields`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Display Fields'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  fieldName`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Field Name'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  actions`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Actions'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  create`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'create'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  update`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'update'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`delete`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'delete'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  read`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'read'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  filter`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'filter'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  sort`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'sort'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  editor`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'editor'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  upload`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'upload'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  tableView`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Table View'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  inputType`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Input Type'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "prisma-table"
      }}>{`Prisma table`}</h2>
      <p>{`React component to list, create, update and delete your model data.`}</p>
      <blockquote>
        <p parentName="blockquote">{`NOTE: This component use 3 queries (findUnique, findMany, findManyCount) and 3 mutations (createOne, updateOne, deleteOne) be sure to add them in your backend by using our CLI `}<a parentName="p" {...{
            "href": "/cli/generator"
          }}><code parentName="a" {...{
              "className": "language-text"
            }}>{`pal generate`}</code></a></p>
      </blockquote>
      <img className="img-border" src="/table.png" alt="table" />
      <h3 {...{
        "id": "using-with-nextjs"
      }}>{`Using with NextJS`}</h3>
      <p>{`Adding style to `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`_app.tsx`}</code>{` file.`}</p>
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`src/pages/_app.tsx`}</code></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/admin/style.css'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`src/Components/PrismaTable.tsx`}</code></p>
      <div {...{
        "className": "gatsby-highlight has-highlighted-lines",
        "data-language": "tsx"
      }}><pre parentName="div" {...{
          "className": "language-tsx"
        }}><code parentName="pre" {...{
            "className": "language-tsx"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` React `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'react'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` useRouter `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'next/router'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}><span parentName="span" {...{
                "className": "token keyword"
              }}>{`import`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` PrismaTable `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{` `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`from`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'@paljs/admin/PrismaTable'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` Table`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` React`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token constant"
            }}>{`FC`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` model`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` model `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` router `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`useRouter`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`return`}</span>{` `}<span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token tag"
                }}><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`<`}</span><span parentName="span" {...{
                    "className": "token class-name"
                  }}>{`PrismaTable`}</span></span>{` `}<span parentName="span" {...{
                  "className": "token attr-name"
                }}>{`model`}</span><span parentName="span" {...{
                  "className": "token script language-javascript"
                }}><span parentName="span" {...{
                    "className": "token script-punctuation punctuation"
                  }}>{`=`}</span><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`{`}</span>{`model`}<span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`}`}</span></span>{` `}<span parentName="span" {...{
                  "className": "token attr-name"
                }}>{`push`}</span><span parentName="span" {...{
                  "className": "token script language-javascript"
                }}><span parentName="span" {...{
                    "className": "token script-punctuation punctuation"
                  }}>{`=`}</span><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`{`}</span>{`router`}<span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`.`}</span>{`push`}<span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`}`}</span></span>{` `}<span parentName="span" {...{
                  "className": "token attr-name"
                }}>{`query`}</span><span parentName="span" {...{
                  "className": "token script language-javascript"
                }}><span parentName="span" {...{
                    "className": "token script-punctuation punctuation"
                  }}>{`=`}</span><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`{`}</span>{`router`}<span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`.`}</span>{`query`}<span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`}`}</span></span>{` `}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`/>`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`default`}</span>{` Table`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h3 {...{
        "id": "using-with-gatsbyjs"
      }}>{`Using with GatsbyJS`}</h3>
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`src/components/PrismaTable.tsx`}</code></p>
      <div {...{
        "className": "gatsby-highlight has-highlighted-lines",
        "data-language": "tsx"
      }}><pre parentName="div" {...{
          "className": "language-tsx"
        }}><code parentName="pre" {...{
            "className": "language-tsx"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` React `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'react'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}><span parentName="span" {...{
                "className": "token keyword"
              }}>{`import`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` PrismaTable `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{` `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`from`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'@paljs/admin/PrismaTable'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` navigate `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'gatsby'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` useLocation `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@reach/router'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`*`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`as`}</span>{` queryString `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'query-string'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/admin/style.css'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` Table`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` React`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token constant"
            }}>{`FC`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` model`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` model `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` location `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`useLocation`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` query `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` queryString`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`parse`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`location`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`search`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`PrismaTable`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`model`}</span><span parentName="span" {...{
                "className": "token script language-javascript"
              }}><span parentName="span" {...{
                  "className": "token script-punctuation punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`model`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`push`}</span><span parentName="span" {...{
                "className": "token script language-javascript"
              }}><span parentName="span" {...{
                  "className": "token script-punctuation punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`navigate`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`query`}</span><span parentName="span" {...{
                "className": "token script language-javascript"
              }}><span parentName="span" {...{
                  "className": "token script-punctuation punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`query`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}></span><span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`default`}</span>{` Table`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h3 {...{
        "id": "props-1"
      }}>{`Props`}</h3>
      <p>{`Prisma Table has many props to can custom it like you want.`}</p>
      <p>{`To customize `}<a parentName="p" {...{
          "href": "https://github.com/paljs/prisma-tools/blob/master/admin/src/PrismaTable/Table/Columns.tsx"
        }}><code parentName="a" {...{
            "className": "language-text"
          }}>{`tableColumns`}</code></a>{` and `}<a parentName="p" {...{
          "href": "https://github.com/paljs/prisma-tools/blob/master/admin/src/PrismaTable/Form/Inputs.tsx"
        }}><code parentName="a" {...{
            "className": "language-text"
          }}>{`formInputs`}</code></a>{` components you need to look to default components and have good react skills.`}</p>
      <div {...{
        "className": "gatsby-highlight has-highlighted-lines",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`interface`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`ModelTableProps`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// customize your table permissions and overwrite the settings it's allow you to give users different permissions`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  actions`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`'create'`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`|`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'update'`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`|`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'delete'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// model name like in \`schema.prisma\` file`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  model`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`string`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// push function to move between tables and change link`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  `}<span parentName="span" {...{
                "className": "token function-variable function"
              }}>{`push`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`url`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`string`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`void`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// link query object used in filters \`?id=1\` => {id: 1}`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  query`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span>{`key`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`string`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`any`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// model pages path you must have all models pages in same path to can move between them.`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// default \`/admin/models/\``}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  pagesPath`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`string`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// in table pagination you can select pageSize you can pass this options here.`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// default: [10, 20, 30, 40, 50, 100]`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  pageSizeOptions`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`number`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// moving between table pages. we not show you all available pages we just see current page and other 3 options.`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// default: 4`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  paginationOptions`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`number`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// add a checkbox for every record on the table you can use for custom cases like delete many`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  onSelect`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`values`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`any`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`void`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// this event call when you click cancel button in create record modal`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  onCancelCreate`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`options`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` model`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`string`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{` `}<span parentName="span" {...{
                "className": "token function-variable function"
              }}>{`setCreateModal`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`state`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`boolean`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`void`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`void`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// this event call when you click save button in create record modal`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  onSaveCreate`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`options`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span></span><span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`    model`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`string`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span><span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`    `}<span parentName="span" {...{
                "className": "token function-variable function"
              }}>{`setCreateModal`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`state`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`boolean`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`void`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span><span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`    `}<span parentName="span" {...{
                "className": "token function-variable function"
              }}>{`refetchTable`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`options`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`any`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`void`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span><span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`void`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// this event call when you click cancel button in edit record page`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  onCancelUpdate`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`options`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` model`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`string`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`void`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// this event call when you click save button in edit record page`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  onSaveUpdate`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`options`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` model`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`string`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{` `}<span parentName="span" {...{
                "className": "token function-variable function"
              }}>{`refetchTable`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`options`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`any`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`void`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`void`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// In create and update form when you click save this function will call before take every value to apollo mutation`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// Here we handle numbers list json values you can use it if you need to add any featuer`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  valueHandler`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`value`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`string`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` field`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` SchemaField`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` create`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`boolean`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`any`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// it's function return object with react table columns https://github.com/tannerlinsley/react-table`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// default here: https://github.com/paljs/prisma-tools/blob/master/admin/src/PrismaTable/Table/Columns.tsx`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  tableColumns`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` GetColumnsPartial`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// it's object with form input components for every field type we use this package https://react-hook-form.com/`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// default here: https://github.com/paljs/prisma-tools/blob/master/admin/src/PrismaTable/Form/Inputs.tsx`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  formInputs`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` Partial`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`<`}</span>{`FormInputs`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`>`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// you can customize the actions buttons`}</span>{`
  actionButtons`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    Add`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` React`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token constant"
            }}>{`FC`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    Update`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` React`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token constant"
            }}>{`FC`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` id`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`any`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    Delete`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` React`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token constant"
            }}>{`FC`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` id`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`any`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  lang`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`typeof`}</span>{` Language`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  dir`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'rtl'`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'ltr'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`FormInputs`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` Record`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'Default'`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Editor'`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Enum'`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Object'`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Date'`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Boolean'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` React`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token constant"
            }}>{`FC`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span>{`InputProps`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`>>`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`interface`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`InputProps`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  field`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` SchemaField`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  value`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`any`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  data`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`any`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  error`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`any`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// import { UseFormReturn } from 'react-hook-form';`}</span>{`
  register`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` UseFormReturn`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'register'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  setValue`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` UseFormReturn`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'setValue'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  getValues`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` UseFormReturn`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'getValues'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  watch`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` UseFormReturn`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'watch'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  disabled`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`boolean`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// import {Column,UseFiltersColumnOptions,UseSortByColumnOptions} from 'react-table';`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`Columns`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` Record`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span>{`
  `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'boolean'`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'number'`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'enum'`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'DateTime'`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'object'`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'string'`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'list'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  Column `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`&`}</span>{` UseFiltersColumnOptions`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="code" {...{
              "className": "token builtin"
            }}>{`any`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`&`}</span>{` UseSortByColumnOptions`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="code" {...{
              "className": "token builtin"
            }}>{`any`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{`
`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`GetColumnsPartial`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`field`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` SchemaField`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` model`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` SchemaModel`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` Partial`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span>{`Columns`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <p>{`The default language object`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` Language `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  yes`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'yes'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  no`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'no'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  all`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'All'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  startDate`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Start Date'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  endDate`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'End Date'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  min`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Min'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  max`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Max'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  range`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Range'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  equals`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Equals'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  deleteConfirm`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Are you sure you want to delete this record ?'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  select`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Select'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  actions`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Actions'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  relation`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Relation'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  viewAll`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'View All'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  viewRelated`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'View Related'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  connected`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Connected'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  connect`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Connect'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  disConnect`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'DisConnect'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  editRow`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Edit Row'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  viewRow`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'View Row'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  deleteRow`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Delete Row'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  showing`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Showing'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`of`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'of'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  results`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'results'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  goToFirstPage`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Go to first page'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  goToLastPage`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Go to last page'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  goPageNumber`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Go Page Number'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  setPageSize`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Set page size '`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  filter`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Filter'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  save`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Save'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  cancel`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Cancel'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  close`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'close'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  create`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'create'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  update`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'update'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  view`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'view'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  isRequired`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`' is required'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  show`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'SHOW'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  clear`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'clear'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  clearAll`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Clear All'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h2 {...{
        "id": "generate-pages"
      }}>{`Generate pages`}</h2>
      <p>{`Now we need to generate a page for every model with our prisma table here `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`src/components/PrismaTable.tsx`}</code>{`.`}</p>
      <p>{`You can add them manulay or use our cli `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`pal generate`}</code></p>
      <p>{`Add to your `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`pal.js`}</code>{` config file`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "text"
      }}><pre parentName="div" {...{
          "className": "language-text"
        }}><code parentName="pre" {...{
            "className": "language-text"
          }}>{`frontend: {
  admin: boolean or object,
},`}</code></pre></div>
      <ul>
        <li parentName="ul">{`Add true To generate pages with default settings`}</li>
        <li parentName="ul">{`you can customize it by add object with this proparty `}<a parentName="li" {...{
            "href": "/generator#adminpagesoptions"
          }}>{`AdminPagesOptions`}</a></li>
      </ul>
      <h3 {...{
        "id": "how-to-add-and-update-list-values"
      }}>{`How to add and update list values?`}</h3>
      <p>{`As we know we can use list values with prisma but how we work on this fields in admin forms`}</p>
      <p><strong parentName="p">{`Example`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "prisma"
      }}><pre parentName="div" {...{
          "className": "language-prisma"
        }}><code parentName="pre" {...{
            "className": "language-prisma"
          }}>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`model`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` SchemaModel`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  id           `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Int`}</span>{`           `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@id`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`autoincrement`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  string       `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{`
  integer      `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Int`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{`
  boolean      `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Boolean`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{`
  float        `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Float`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{`
  json         `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Json`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`enum`}</span>{`s        `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` FieldKind`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`enum`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` FieldKind`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  object
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`enum`}</span>{`
  scalar
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
      <p>{`This fields inputs will ba as text type`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`Int[]`}</code>{` => `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`1,2,3,4`}</code>{` converted to `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`[1,2,3,4]`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`Flout[]`}</code>{` => `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`1.2,2.3,3.5`}</code>{` converted to `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`[1.2,2.3,3.5 ]`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`String[]`}</code>{` => `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`first,second`}</code>{` converted to `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`['first','second']`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`Boolean[]`}</code>{` => `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`true,false`}</code>{` converted to `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`[true,false]`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`enum[]`}</code>{` => `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`object,enum `}</code>{`converted to `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`['object','enum']`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`Json[]`}</code>{` => `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`[{"first": 1},{"second": 2}] `}</code>{`we will pass value throught `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`JSON.parse()`}</code></li>
      </ul>
    </MdxCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      